import { useQuery } from '@tanstack/react-query';
import dynamic from 'next/dynamic';
import NextLink from 'next/link';
import PropTypes from 'prop-types';
import { twJoin } from 'tailwind-merge';

import Badge from 'shopper/components/Badge';
import Button from 'shopper/components/Button';
import Icon from 'shopper/components/Icon';

import { sendEvent } from 'lib/gtag';
import placeholder from 'lib/placeholder';
import { landingQueryKeys } from 'lib/queryKeys';
import {
  formatSponsorSessionStorageKey,
  getFromSessionStorage,
  setSessionStorage,
} from 'lib/sessionStorage';

import { getLandingsList } from 'services/landing';

import CategoriesDropdownButton from './CategoriesDropdownButton';
import StoresDropdownButton from './StoresDropdownButton';

import LINKS from 'constants/links';
import WHATSAPP_GROUP from 'constants/whatsappGroupCampaign';

const FeaturedLandingLink = dynamic(() => import('./FeaturedLandingLink'), {
  ssr: true,
});

const ITEMS_TYPES = {
  BUTTON: 'button',
  SPONSOR: 'sponsor',
  TAB: 'tab',
};

const MAIN_BUTTONS_LIST = [
  {
    href: LINKS.BLOG,
    backgroundHref: null,
    text: placeholder('actions.seeBlog'),
    gaEventAction: 'topbar_blog_tab',
    testSelectorAttr: 'blog-tab',
    icon: <Icon name="article" />,
    type: ITEMS_TYPES.TAB,
  },
  {
    href: LINKS.COUPONS,
    backgroundHref: null,
    text: placeholder('actions.seeCoupons'),
    gaEventAction: 'topbar_coupon_tab',
    testSelectorAttr: 'coupons-tab',
    icon: <Icon name="discount-filled" />,
    type: ITEMS_TYPES.TAB,
  },
  {
    href: LINKS.FORUM,
    backgroundHref: null,
    text: placeholder('actions.seeForum'),
    gaEventAction: 'topbar_forum_tab',
    testSelectorAttr: 'forum-tab',
    icon: <Icon name="messages" />,
    type: ITEMS_TYPES.TAB,
  },
  {
    href: LINKS.ALIEXPRESS_BIG_DEALS,
    backgroundHref: LINKS.ALIEXPRESS_FIRST_PURCHASE_DEALS,
    text: placeholder('actions.seeAliexpressSponsored'),
    gaEventAction: 'aliexpress_sponsor_tab',
    testSelectorAttr: 'aliexpress_sponsor_tab',
    icon: <Icon name="aliexpress" />,
    type: ITEMS_TYPES.SPONSOR,
  },
  {
    href: WHATSAPP_GROUP.DEFAULT_GROUP_URL,
    backgroundHref: null,
    text: placeholder('actions.seeWhatsAppGroup'),
    gaEventAction: 'whatsapp_group_tab',
    testSelectorAttr: null,
    icon: <Icon name="whatsapp-filled" />,
    type: ITEMS_TYPES.BUTTON,
  },
];

const TopbarBottomDefaultNav = ({ gaEventCategory }) => {
  const { data: featuredLandings, isLoading } = useQuery({
    queryKey: landingQueryKeys.featured(),
    queryFn: ({ signal }) => getLandingsList({ featured: true }, { signal }),
    select: (data) => data.landings,
    enabled: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
  });

  return (
    <>
      <CategoriesDropdownButton gaEventCategory={gaEventCategory} />
      <StoresDropdownButton gaEventCategory={gaEventCategory} />
      {MAIN_BUTTONS_LIST.map(
        ({
          backgroundHref,
          gaEventAction,
          href,
          icon,
          type,
          testSelectorAttr,
          text,
        }) => {
          const isSponsored = type === ITEMS_TYPES.SPONSOR;
          const isButton = type === ITEMS_TYPES.BUTTON;
          const sessionStorageKey = formatSponsorSessionStorageKey(text);
          const isSponsorBeenVisited = getFromSessionStorage(sessionStorageKey);
          const showBadge = (isSponsored || isButton) && !isSponsorBeenVisited;

          const Item = (
            <Button
              key={href}
              as="a"
              className={twJoin(
                'relative mr-4 cursor-pointer',
                /**
                 * This is not deal, but to avoid create a third type of
                 * WhatsApp icon we prefer to customize the existing one.
                 */
                isButton &&
                  '[&>svg]:text-success-300 [&>svg]:hover:text-success-300 dark:[&>svg]:text-success-300 dark:[&>svg]:hover:text-success-300'
              )}
              data-test-selector={testSelectorAttr}
              href={isSponsored ? backgroundHref : href}
              iconLeft={icon}
              rel="noopener noreferrer"
              size="size4"
              target={isButton ? '_blank' : '_self'}
              type="neutral-ghost"
              onClick={async () => {
                if (showBadge) {
                  setSessionStorage(sessionStorageKey, true);
                }

                if (isSponsored) {
                  window.open(href, '_blank');
                }

                sendEvent({
                  action: gaEventAction,
                  category: gaEventCategory,
                });
              }}
            >
              {text}
              {showBadge && (
                <Badge
                  className="absolute right-0 top-1"
                  shape="dot"
                  type="highlight"
                />
              )}
            </Button>
          );

          if (isSponsored || isButton) {
            return Item;
          }

          return (
            <NextLink key={href} href={href} prefetch={false} passHref>
              {Item}
            </NextLink>
          );
        }
      )}
      {!isLoading &&
        featuredLandings.map(
          ({ landingId, landingSlug, landingName, landingType }, index) => (
            <FeaturedLandingLink
              key={landingId}
              gaEventCategory={gaEventCategory}
              landingId={landingId}
              landingName={landingName}
              landingSlug={landingSlug}
              landingType={landingType}
              position={index + 0}
            />
          )
        )}
    </>
  );
};

TopbarBottomDefaultNav.propTypes = {
  gaEventCategory: PropTypes.string.isRequired,
};

export default TopbarBottomDefaultNav;
